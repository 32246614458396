.bg-bubbles {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.bg-bubbles div {
    position: absolute;
    display: block;
    width: 95px;
    height: 95px;
    background: rgba(15, 87, 160, 0.3);
    animation: animate 20s linear infinite;
    bottom: -150px;
}

.bg-bubbles div:nth-child(1){
    left: 5%;
    width: 80px;
    height: 80px;
    animation-delay: 10s;
}

.bg-bubbles div:nth-child(2){
    left: 15%;
    width: 50px;
    height: 50px;
    animation-delay: 6s;
}

.bg-bubbles div:nth-child(3){
    left: 22%;
    width: 120px;
    height: 120px;
    animation-delay: 15s;
}

.bg-bubbles div:nth-child(4){
    left: 33%;
    width: 78px;
    height: 78px;
    animation-delay: 24s;
}

.bg-bubbles div:nth-child(5){
    left: 45%;
    width: 95px;
    height: 95px;
    animation-delay: 7s;
}

.bg-bubbles div:nth-child(6){
    left: 58%;
    width: 42px;
    height: 42px;
    animation-delay: 22s;
}

.bg-bubbles div:nth-child(7){
    left: 66%;
    width: 88px;
    height: 88px;
    animation-delay: 10s;
}

.bg-bubbles div:nth-child(8){
    left: 75%;
    width: 45px;
    height: 45px;
    animation-delay: 18s;
}

.bg-bubbles div:nth-child(9){
    left: 82%;
    width: 72px;
    height: 72px;
    animation-delay: 0s;
}

.bg-bubbles div:nth-child(10){
    left: 92%;
    width: 68px;
    height: 68px;
    animation-delay: 19s;
}

@keyframes animate {

0%{
    transform: translateY(0) rotate(0deg);
    opacity: 1;
    border-radius: 0;
}

100%{
    transform: translateY(-1000px) rotate(720deg);
    opacity: 0;
    border-radius: 50%;
}

}