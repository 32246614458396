.text-block {
    -webkit-filter: drop-shadow(0px 0.5rem 0.4rem rgba(37, 32, 32, 0.2));
            filter: drop-shadow(0px 0.5rem 0.4rem rgba(37, 32, 32, 0.2))
}

.separate-line{
    height:2px;
    background-color:grey;
    width: 80%;
    left: 0;
    right: 0;
    margin: auto;
}