.contact-message {
    position: relative;
    color: white;
    background-image: linear-gradient(rgba(15, 87, 160, 0.8), rgba(15, 87, 160, 0.8)), url("https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/footer-background.jpg");
}

.mobileSide {
    background-image: linear-gradient(rgba(231, 200, 92, 0.8), rgba(231, 200, 92, 0.8)), url("https://haiyantest-1304231816.cos.ap-guangzhou.myqcloud.com/HaiyanResources/SomeImages/phone.png");
}

.verticleText {
    writing-mode: vertical-rl;
}

.pcSide .emphasize-text {
    background-image: linear-gradient(180deg, rgba(231, 200, 92, 0) 50%, rgba(231, 200, 92, 1) 50%, rgba(231, 200, 92, 1) 100%);
    background-position: 100% 90%;
    background-size: 100% 30%;
    background-repeat: no-repeat;
}

.mobileSide .emphasize-text {
    background-image: linear-gradient(180deg, rgba(15, 87, 160, 1) 50%, rgba(15, 87, 160, 1) 50%, rgba(15, 87, 160, 0.8) 100%);
    background-position: 100% 90%;
    background-size: 100% 20%;
    background-repeat: no-repeat;
}

.upper-text {
    left: 0;
    right: 0;
    border-radius: 30px 30px 30px 30px;
    box-shadow: 5px 5px 15px 4px rgba(37, 57, 33, 0.24);
}

.slope {
    stroke: #E7C85C;
    stroke-width: 3px;
    width: 13%;
    height: 250px;
}