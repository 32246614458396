.loading-animation {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform-origin: center;
}

.real-animation{
    width: 50%;
    height: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    transform-origin: center;
}