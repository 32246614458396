.contact-message-mobile {
    position: relative;
    height: 20rem;
    left: 0;
    right: 0;
    margin: auto;
    top: -3rem;
    background-color: #E7C85C;
    border-radius: 5rem 5rem 5rem 5rem;
}

.separate-line-mobile {
    height: 2px;
    background: #FFFFFF;
    width: 20rem;
}

.questEn {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}