.container {
    width: 100vw;
    max-width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-wrap {
    width: 100vw;
}

.slider-wrap img {
    width: 100%;
    height: 100vh;
    display: block;
    object-fit: cover;
    user-select: none;
}

.slider-wrap .after-wrap {
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.slider-wrap .after-wrap img {
    display: block;
    width: 100vw;
    max-width: 100vw;
}

.handler {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 2px;
    height: 100%;
    background-color: rgba(255, 255, 255, 1);
    position: absolute;
    z-index: 20;
    top: 0;
    left: calc(50%);
    cursor: col-resize;
    touch-action: none;
}

.handler-circle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: absolute;
    align-items: center;
    width: 48px;
    height: 48px;
    background-color: rgba(0, 0, 0, 0.2);
    top: 50%;
    left: calc(-24px + 50%);
    border: 0.5px solid white;
    border-radius: 50%;
}

.handler-circle:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.triangle {
    fill: transparent;
    stroke: white;
    stroke-width: 1;
    width: 20px;
    height: 20px;
}
