.submit-button {
    --border: 5px;
    --color: #0F57A0;

    color: var(--color);
    background: linear-gradient(to bottom left, var(--color) 50%, #0000 50.1%) top right,
        linear-gradient(to top right, var(--color) 50%, #0000 50.1%) bottom left;

    background-size: calc(1.3*var(--border)) calc(1.3*var(--border));
    background-repeat: no-repeat;

}


.submit-button .title {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
}