.customerSection {
    padding: 5rem 0 6rem 0;
}

.coTitleEn {
    font-family: 'Montserrat', sans-serif;
    font-weight: 200;
}

.separateLine {
    background-color: rgba(241, 91, 36, 0.13);
}

.bgCircle {
    background-color: rgba(241, 91, 36, 0.13);
    width: 600px;
    height: 600px;
    position: absolute;
    right: -480px;
    top: -200px;
    z-index: -20;
    transform: rotateZ(45deg);
}

.bgCircle2 {
    background-color: rgba(241, 91, 36, 0.13);
    width: 560px;
    height: 560px;
    position: absolute;
    left: -400px;
    bottom: -200px;
    z-index: -20;
    transform: rotateZ(-45deg);
}